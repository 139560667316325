var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "title" }, [_vm._v("最新动态")]),
      _c("div", { staticClass: "contents" }, [
        _c("div", { staticClass: "news-list" }, [
          _c("img", {
            attrs: { src: require("@/assets/aboutUs/dynamic1.png") },
          }),
          _c("dl", [
            _c("dt", [_vm._v("2020-10 | 与华为达成长期合作")]),
            _c("dd", [
              _vm._v(
                " 2020年10月21日，智迩高层管理访问华为苏州研发中心，智迩作为华为金牌介绍公司的业务发展，并与华为在金融及政府行业达成深入长期合作 "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "news-list" }, [
          _c("img", {
            attrs: { src: require("@/assets/aboutUs/dynamic2.png") },
          }),
          _c("dl", [
            _c("dt", [_vm._v("2024-01 | 公司年会活动")]),
            _c("dd", [
              _vm._v(
                "2024年1月，公司成立五周年，组织公司年会，公司创始人和员工团体聚餐。"
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }